// @flow
import React, { type Element, useState, useEffect } from 'react';
import classnames from 'classnames';
import * as R from 'ramda';
import { useFormContext } from 'react-hook-form';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { useTranslation } from 'react-i18next';
import uuid from 'uuid/v4';
import type {
  WeeklySlotT,
  WeeklySlotWithRangeT
} from '../../../../../../ducks/entities/calendar/calendarTypes';
import EditTimeSlotModal from './EditTimeSlotModal';
import WeekCalendar from '../../../../../../components/ReactWeekCalendar';

import './WeekCalendar.scss';
import styles from './CalendarField.module.scss';

export type PropsT = {|
  field: string,
  title?: string,
  description?: string,
  singleSlotPerDay?: boolean,
  className?: string
|};

const moment = extendMoment(Moment);

const toRanges = (intervals: WeeklySlotT[]): WeeklySlotWithRangeT[] =>
  intervals.map(interval => ({
    ...interval,
    range: moment.range(interval.start, interval.end)
  }));

const toIntervals = (ranges: WeeklySlotWithRangeT[]): WeeklySlotT[] =>
  ranges
    .map(range => ({
      ...range,
      start: range.range.start,
      end: range.range.end,
      value: ''
    }))
    .map(range => R.omit(['range'], range));

const removeContained = (ranges: WeeklySlotWithRangeT[]): WeeklySlotWithRangeT[] =>
  // remove all ranges that are completely contained within other ranges
  ranges.filter(
    rangeA =>
      !ranges.some(
        rangeB =>
          rangeA.uid !== rangeB.uid &&
          rangeB.range.contains(rangeA.start) &&
          rangeB.range.contains(rangeA.end)
      )
  );
const mergeOverlapping = (ranges: WeeklySlotWithRangeT[]): WeeklySlotWithRangeT[] => {
  // merge all overlapping (or adjacent) ranges
  for (let i = 0; i < ranges.length; i++) {
    const rangeA = ranges[i];
    for (let j = 0; j < ranges.length; j++) {
      const rangeB = ranges[j];
      if (
        rangeB &&
        rangeA.uid !== rangeB.uid &&
        rangeA.range.overlaps(rangeB.range, { adjacent: true })
      ) {
        // merge with overlapping and delete unneeded range
        rangeB.range = rangeB.range.add(rangeA.range, { adjacent: true });
        ranges[j] = rangeB; // eslint-disable-line
        ranges.splice(i, 1);
        --i;
        --j;
        break;
      }
    }
  }
  return ranges;
};

const removeContainedAndMergeOverlapping = (intervals: WeeklySlotT[]): WeeklySlotT[] => {
  const ranges = toRanges(intervals);
  const containedRemoved = removeContained(ranges);
  const merged = mergeOverlapping(containedRemoved);
  return toIntervals(merged);
};

const replaceDaySlotsByNewIntervals = (newIntervals: WeeklySlotT[], weekslots: WeeklySlotT[]) => {
  const indices = newIntervals.map(v => v.start.day());
  return [...weekslots.filter(v => !indices.includes(v.start.day())), ...newIntervals];
};

export const CalendarField = (props: PropsT): Element<'div'> => {
  const { field, title, description, singleSlotPerDay, className } = props;
  const { t, i18n } = useTranslation();
  const activeLanguage = i18n.language;

  // form
  const { setValue, watch } = useFormContext();
  const values = watch();

  // state
  const [selectedIntervals, setSelectedIntervals] = useState(values[field] || []);
  const [lastUid, setLastUid] = useState(selectedIntervals.length);
  const [calendarId, setCalendarId] = useState(Math.random().toString());

  const handleEventUpdate = event => {
    const index = selectedIntervals.findIndex(interval => interval.uid === event.uid);
    if (index > -1) {
      selectedIntervals[index] = { ...event, uid: uuid() };
      const intrvls = removeContainedAndMergeOverlapping(selectedIntervals);
      setSelectedIntervals(intrvls);
      setValue(field, intrvls, {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  const handleSelect = newIntervals => {
    const intervals = (newIntervals || []).map((interval, index) => ({
      ...interval,
      uid: lastUid + index
    }));

    const intrvls = singleSlotPerDay
      ? replaceDaySlotsByNewIntervals(intervals, selectedIntervals)
      : removeContainedAndMergeOverlapping(selectedIntervals.concat(intervals));

    setSelectedIntervals(intrvls);
    setLastUid(lastUid + newIntervals.length);
    setValue(field, intrvls, {
      shouldValidate: true,
      shouldDirty: true
    });
  };

  const handleEventRemove = event => {
    const index = selectedIntervals.findIndex(interval => interval.uid === event.uid);
    if (index > -1) {
      selectedIntervals.splice(index, 1);
      setSelectedIntervals(selectedIntervals);
      setValue(field, [...selectedIntervals], {
        shouldValidate: true,
        shouldDirty: true
      });
    }
  };

  const HeaderCell = cellProps => {
    const weekday: number = cellProps.date.format('D');
    const weekdayTranslations = [
      t('calendars.weekdays.monday'),
      t('calendars.weekdays.tuesday'),
      t('calendars.weekdays.wednesday'),
      t('calendars.weekdays.thursday'),
      t('calendars.weekdays.friday'),
      t('calendars.weekdays.saturday'),
      t('calendars.weekdays.sunday')
    ];
    return <span className={styles.weekdayHeaderCell}>{weekdayTranslations[weekday - 1]}</span>;
  };

  useEffect(() => {
    const element = document.getElementsByClassName('weekCalendar')[0];
    element.scrollTop = 584;
    setCalendarId(Math.random().toString());
  }, [activeLanguage]);

  const EditTimeSlotModal2 = p => <EditTimeSlotModal {...p} translateMock={t} />;

  return (
    <div className={classnames(styles.container, className)}>
      {title && <div className={styles['input-title']}>{title}</div>}
      {description && <div className={styles['input-description']}>{description}</div>}
      <WeekCalendar
        key={calendarId}
        firstDay={moment({ d: 1 })}
        startTime={moment({ h: 0, m: 0 })}
        endTime={moment({ h: 23, m: 59 })}
        scaleUnit={60}
        cellHeight={25}
        numberOfDays={7}
        selectedIntervals={selectedIntervals}
        onIntervalSelect={handleSelect}
        onIntervalUpdate={handleEventUpdate}
        onIntervalRemove={handleEventRemove}
        modalComponent={EditTimeSlotModal2}
        headerCellComponent={HeaderCell}
        useModal
        eventSpacing={1}
        showModalCase={['edit']}
        scaleFormat="HH:mm"
      />
      <div className={styles.midnight}>24:00</div>
    </div>
  );
};

export default CalendarField;
